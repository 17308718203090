import axios from "axios";
import { address } from "./server";

export const markAttendance = (attendance) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${address}/api/user/attendance`, attendance);
            dispatch({
                type: "MARK_ATTENDANCE",
                payload: response.data
            });
        } catch (error) {
            console.log(error);
        }
    }
}

export const clearQueryState = () => {
    return {
        type: "CLEAR_QUERY_STATE",
    };
}

