import React from "react";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import { useState, useEffect } from "react";
import logo from "../../assets/logo/tas.png";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/now-ui-kit.css";
import "../../assets/demo/demo.css";
const DashboardNavbar = () => {
  
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [colorChange, setColorchange] = useState(true);
  // function that adds color changes navbar color when scrolling
  const changeNavbarColor = () => {
    if (window.scrollY >= 289) {
      setColorchange(false);
    } else {
      setColorchange(true);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      <Navbar
        className={colorChange ? "fixed-top navbar-transparent" : "fixed-top"}
        expand="lg"
        color="danger"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand target="_blank" id="navbar-brand">
              The Arqam School Chak Jhumra
            </NavbarBrand>
            <NavbarBrand target="_blank" id="navbar-brand">
              <img
                src={logo}
                alt="logo"
                style={{ width: "75px", height: "75px" }}
              />
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="/" id="navbar-brand">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about" id="navbar-brand">
                  About
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/admissions" id="navbar-brand">
                  Admissions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact" id="navbar-brand">
                  Contact
                </NavLink>
              </NavItem>
           
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default DashboardNavbar;
