import React, { useState, useEffect } from 'react'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { sha256 } from 'js-sha256';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, clearQueryState } from "../../actions/loginAction"

const Addstudent = () => {
    const dispatch = useDispatch();
    const userName = useSelector(state => state.loginReducer.Name);
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [id, setId] = useState('')
    const [hashedPassword, setHashedPassword] = useState('');
    const [className, setClassName] = useState('None');
    const [section, setSection] = useState('None')
    const [fatherName, setFatherName] = useState("");
    const [contactNumber, setContactNumber] = useState("")
    const [dob, setDob] = useState("")

    const [isLoading, setIsLoading] = useState(false);

    let queryRun = useSelector(state => state.loginReducer.queryRun);
    useEffect(() => {
        if (queryRun) {
            setIsLoading(false);
            alert('Student added successfully');
            dispatch(clearQueryState());
        }
    }
        , [queryRun])

    // if (isLoading == true) {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //         alert("Connection Error");
    //     }

    //         , 10000);
    // }


    const handleChange = (event) => {
        if (event.target.name === 'className') {
            setClassName(event.target.value.toString());
        } else if (event.target.name === 'section') {
            setSection(event.target.value.toString());
        }
    }

    const handleSubmit = () => {
        if (name === '' || password === '' || id === '' || className === 'None' || section === 'None' || fatherName === '' || contactNumber === '' || dob === '') {
            alert('Please fill all the fields');
        }
        else {
            dispatch(addUser(name, hashedPassword, className, id, dob, contactNumber, fatherName, '', '', '', 'student', [], section, userName))
            setIsLoading(true);
            setName('');
            setPassword('');
            setId('');
            setClassName('None');
            setSection('None');
            setFatherName('');
            setContactNumber('');
            setDob('');
            setHashedPassword('');
        }
    }
    if (isLoading) {
        return <div className='addstudent-loader'>
            < CircularProgress />
        </div >
    }



    return (
        <div>
            <div className="home-heading mt-3">
                Add Student
            </div>
            <Divider />
            <div className='content'>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        margin: '0 auto',
                        maxWidth: '500px',
                        padding: '0px',
                        width: '100%',
                        marginTop: '20px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        backgroundColor: '#f5f5f5',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',

                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div>
                        <TextField
                            required
                            id="outlined-required"
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            required
                            id="outlined-required"
                            label="User ID"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                        />

                        <TextField
                            required
                            id="outlined-password-input"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => {
                                setHashedPassword(sha256(e.target.value));
                                setPassword(e.target.value)
                            }}
                        />
                        <TextField
                            required
                            id="outlined-required"
                            label="Father Name"
                            value={fatherName}
                            onChange={(e) => setFatherName(e.target.value)}
                        />

                        <TextField
                            required
                            id="outlined-required"
                            label="Contact Number"
                            value={contactNumber}
                            onChange={(e) => setContactNumber(e.target.value)}
                        />
                        <TextField
                            required
                            id="outlined-required"
                            label="DOB (DD-MM-YYYY)"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>

                            <DesktopDatePicker
                                label="Date desktop"
                                inputFormat="dd/MM/yyyy"
                                value={dob}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />

                        </LocalizationProvider> */}
                        <div className='select-container ml-2'>
                            <InputLabel id="demo-simple-select-helper-label" className=''>Class</InputLabel>
                            <Select
                                className='ml-2'
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={className}
                                label="Class"
                                onChange={handleChange}
                                name="className"
                            >
                                <MenuItem value="None">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={1}>One</MenuItem>
                                <MenuItem value={2}>Two</MenuItem>
                                <MenuItem value={3}>Three</MenuItem>
                                <MenuItem value={4}>Four</MenuItem>
                                <MenuItem value={5}>Five</MenuItem>
                                <MenuItem value={6}>Six</MenuItem>
                                <MenuItem value={7}>Seven</MenuItem>
                                <MenuItem value={8}>Eight</MenuItem>
                                <MenuItem value={9}>Nine</MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>

                            </Select>
                            <InputLabel id="demo-simple-select-helper-label" className='select-container-classSection'>Class section</InputLabel>
                            <Select
                                className='ml-2'
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={section}
                                label="Class section"
                                onChange={handleChange}
                                name="section"
                            >
                                <MenuItem value="None">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"A"} >A</MenuItem>
                                <MenuItem value={"B"} >B</MenuItem>
                                <MenuItem value={"C"} >C</MenuItem>
                                <MenuItem value={"D"} >D</MenuItem>

                            </Select>
                        </div>

                    </div>
                    <div className='addstudent-button'>
                        <Button onClick={handleSubmit} variant="contained">Submit</Button>
                    </div>




                </Box>
            </div>
        </div>
    )
}

export default Addstudent