import React from "react";
import { Button, Container } from "reactstrap";
import { createRef, useEffect } from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/now-ui-kit.css";
import "../../assets/demo/demo.css";
const DashboardHeader = () => {
  let pageHeader = createRef();
  // THIS EFFECT HANDLES SCROLLING AND ADJUSTMENTS TO THE HEADER
  useEffect = () => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  };

  return (
    <div>
      <div className="page-header page-header-small">
        {/* This Div loads the background image of the header */}
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("../../assets/imgs/p3.JPG") + ")",
          }}
          ref={pageHeader}
        ></div>
        {/* This Div loads the content of the header */}
        <div className="content-center">
          <Container>
            <h1 className="title">Striving for Excellence.</h1>
            <div className="text-center">
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.facebook.com/TheArqamSchoolCKJ"
                target="_blank"
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.instagram.com/tas_jhumra/"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://wa.me/923258310007"
                target="_blank"
              >
                <i className="fab fa-whatsapp"></i>
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
