import React, { useState, useEffect } from 'react'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { getClassStudents } from '../../actions/userActions';
import { uploadResult, clearQueryState } from '../../actions/resultActions';
const Result = () => {
    const dispatch = useDispatch()
    const [student, setStudent] = useState("None")
    const [studentId, setStudentId] = useState("");
    const [classOfStud, setClassOfStud] = useState("")
    const [term, setTerm] = useState("None")
    const [get, setGet] = useState(true)
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const className = useSelector(state => state.loginReducer.user.grade);
    const classSection = useSelector(state => state.loginReducer.user.section);
    let queryRun = useSelector(state => state.resultReducer.queryRun);
    let error = useSelector(state => state.resultReducer.error);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }
            , 1000);
    }
        , []);

    useEffect(() => {
        if (get) {
            let cs = className + "-" + classSection;
            dispatch(getClassStudents(cs));
            setGet(false);
        }
    }, [get])

    useEffect(() => {
        if (queryRun && !error) {
            setIsLoading(false);
            alert('Result added successfully');
            dispatch(clearQueryState());
        }
        else if (queryRun && error) {
            setIsLoading(false);
            alert('Failed!');
            dispatch(clearQueryState());
        }
    }
        , [queryRun])

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);

        setIsFilePicked(true);

    };
    const handleSubmission = () => {
        if (isFilePicked && student != "None" && term != "None") {
            setIsLoading(true)
            dispatch(uploadResult(selectedFile, studentId, classOfStud, term))
            setStudent("None")
            setStudentId("")
            setClassOfStud("")
            setTerm("None")
            setSelectedFile()
            setIsFilePicked(false)
        }
        else if (student == "None") {
            alert("Please select a student")
        }
        else if (term == "None") {
            alert("Please select a term")
        }
        else if (isFilePicked == false) {
            alert("Please select a file")
        }
    }
    let classStudents = useSelector(state => state.userReducer.classStuds);

    const handleChangeId = (event) => {
        const res = event.target.value.split("-")
        setStudent(event.target.value)
        setStudentId(res[0]);
        setClassOfStud(classStudents[res[1]].grade)
    }
    const handleChangeTerm = (event) => {
        setTerm(event.target.value)
    }

    if (isLoading) {
        return <div className='addstudent-loader'>
            < CircularProgress />
        </div >
    }

    return (
        <div>
            <div className="home-heading mt-3">
                Add Result
            </div>
            <Divider />
            <div className='content'>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        // margin: '0 auto',
                        // maxWidth: '500px',
                        padding: '0px',
                        width: '100%',
                        marginTop: '20px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        backgroundColor: '#f5f5f5',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',

                    }}
                    noValidate
                    autoComplete="off"
                >
                    {/* <div className="student-name-select-container mt-2"> */}
                    <InputLabel id="demo-simple-select-helper-label" className='mt-2'>Student</InputLabel>
                    <Select
                        className='ml-2'
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={student}
                        label="Class"
                        onChange={handleChangeId}
                        name="className"
                    >
                        <MenuItem value="None">
                            <em>None</em>
                        </MenuItem>
                        {classStudents != null && classStudents.map((student, index) => {
                            return (
                                <MenuItem value={student.id + "-" + index} key={index}>{student.name}</MenuItem>
                            )
                        }
                        )}
                    </Select>
                    <InputLabel id="demo-simple-select-helper-label" className='mt-2'>Term</InputLabel>
                    <Select
                        className='ml-2'
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={term}
                        label="Class"
                        onChange={handleChangeTerm}
                        name="className"
                    >
                        <MenuItem value="None">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="1" >1</MenuItem>
                        <MenuItem value="2" >2</MenuItem>
                    </Select>
                    {/* </div> */}

                    <div className='file-info ml-2 mr-2'>
                        {isFilePicked ? (
                            <div>
                                <p>Filename: {selectedFile.name}</p>
                                <p>Size in bytes: {selectedFile.size}</p>

                            </div>
                        ) : (
                            <p>Select a file to show details</p>
                        )}
                    </div>
                    <div className='select-button'>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            <input
                                onChange={changeHandler}
                                type="file"
                                hidden
                            />
                            Select File
                        </Button>
                    </div>
                    <div className='upload-button'>
                        <Button
                            variant="contained"
                            component="label"
                            onClick={handleSubmission}
                        >
                            Upload
                        </Button>
                    </div>

                    {/* <div>
                        <TextField
                            required
                            id="outlined-required"
                            label="Name"

                        />
                        <TextField
                            required
                            id="outlined-required"
                            label="User ID"

                        />

                        <TextField
                            required
                            id="outlined-required"
                            label="User ID"

                        />
                        <TextField
                            required
                            id="outlined-required"
                            label="Father Name"

                        />

                        <TextField
                            required
                            id="outlined-required"
                            label="Contact Number"

                        />
                        <TextField
                            required
                            id="outlined-required"
                            label="DOB (DD-MM-YYYY)"

                        />

                    </div>
                    <div className='addstudent-button'>
                        <Button variant="contained">Submit</Button>
                    </div> */}
                </Box>
            </div>
        </div>

    )
}

export default Result