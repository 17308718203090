import axios from "axios";
import { address } from "./server";

export const getClassStudentsAttendance = (classId) => {
    return async (dispatch) => {
        const response = await axios.get(`${address}/api/user/class/attendance/${classId}`);
        dispatch({
            type: "GET_CLASS_STUDENTS_ATTENDANCE",
            payload: response.data,
        });
    };
}
export const getClassStudents = (classId) => {
    return async (dispatch) => {
        const response = await axios.get(`${address}/api/user/class/${classId}`);
        dispatch({
            type: "GET_CLASS_STUDENTS",
            payload: response.data,
        });
    };
}

export const attendanceMarked = (attendanceData) => {
    return async (dispatch) => {
        dispatch({
            type: "ATTENDANCE_MARKED",
            payload: "Already updated",
        });
    };
}
