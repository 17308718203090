import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { useDispatch, useSelector } from 'react-redux'
import { addUser, clearQueryState, logout } from '../../actions/loginAction';
import { useNavigate } from "react-router-dom";

import Navbar from '../Navbar'
import Loading from '../Loading'
import sha256 from 'js-sha256';

const AdminPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [hashedPassword, setHashedPassword] = useState('');
  const [className, setClassName] = useState('None');
  const [section, setSection] = useState('')
  const [id, setId] = useState('')
  const [isLoading, setIsLoading] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let queryRun = useSelector(state => state.loginReducer.queryRun);
  let allowed = useSelector(state => state.loginReducer.allowed);
  useEffect(() => {
    setIsLoading(false);

    if (!allowed) {
      navigate("/");
    }
  }, [allowed])


  useEffect(() => {
    if (queryRun) {
      setIsLoading(false);
      alert('Teacher added successfully');
      dispatch(clearQueryState());
    }
  }
    , [queryRun])

  const handleChange = (event) => {
    if (event.target.name === 'className') {
      setClassName(event.target.value.toString());
    } else if (event.target.name === 'section') {
      setSection(event.target.value.toString());
    }
  }

  if (isLoading) {
    return <Loading />
  }

  const handleSubmit = () => {
    if (name != '' && password != '' && className != 'None' && section != '') {
      dispatch(addUser(name, hashedPassword, className, id, '', '', '', '', '', '', 'teacher', '', section))
      handleClose();
      setName('');
      setPassword('');
      setClassName('None');
      setSection('');
      setId('');
      setHashedPassword('');
      setIsLoading(true);

    }
    else {
      alert('Please fill all the fields');
    }

  }

  if (isLoading) {
    return <Loading />
  }


  return (
    <div className="app">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-md-4 mt-5">
            <Button onClick={handleOpen} variant="contained">Add Teacher</Button>
          </div>
          <div className="col-md-8 mt-5">
            <Button variant="contained" color="warning" onClick={() => {
              dispatch(logout());
              navigate('/signin');
            }}>
              <Typography variant="button">
                Log Out
              </Typography>

            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mt-5">
            {open ?
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <Button onClick={handleSubmit} variant="contained">Submit</Button>

                <div>
                  <TextField
                    required
                    id="outlined-required"
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label="User ID"
                    defaultValue="id"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />

                  <TextField
                    id="outlined-password-input"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => {
                      setHashedPassword(sha256(e.target.value));
                      setPassword(e.target.value)
                    }}
                  />
                  <InputLabel id="demo-simple-select-helper-label">Class</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={className}
                    label="Class"
                    onChange={handleChange}
                    name="className"
                  >
                    <MenuItem value="None">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>One</MenuItem>
                    <MenuItem value={2}>Two</MenuItem>
                    <MenuItem value={3}>Three</MenuItem>
                    <MenuItem value={4}>Four</MenuItem>
                    <MenuItem value={5}>Five</MenuItem>
                    <MenuItem value={6}>Six</MenuItem>
                    <MenuItem value={7}>Seven</MenuItem>
                    <MenuItem value={8}>Eight</MenuItem>
                    <MenuItem value={9}>Nine</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>

                  </Select>
                  <InputLabel id="demo-simple-select-helper-label">Class section</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={section}
                    label="Class section"
                    onChange={handleChange}
                    name="section"
                  >
                    <MenuItem value="None">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"A"} >A</MenuItem>
                    <MenuItem value={"B"} >B</MenuItem>
                    <MenuItem value={"C"} >C</MenuItem>
                    <MenuItem value={"D"} >D</MenuItem>

                  </Select>

                </div>


              </Box>
              : null}
          </div>
        </div>

      </div>
    </div>
  )
}

export default AdminPage