import axios from "axios";
import { address } from "./server";

export const addAnnouncement = (announcement) => {
    return async (dispatch) => {
        const response = await axios.post(`${address}/api/announcement`, announcement);
        dispatch({
            type: "ADD_ANNOUNCEMENT",
            payload: response.data,
        });
    };
}
export const clearQueryState = () => {
    return {
        type: "CLEAR_QUERY_STATE",
    };
}

