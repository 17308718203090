const initialData = {
    queryRun: false,
    error: false
}
const resultReducer = (state = initialData, action) => {
    switch (action.type) {
        case "UPLOAD_RESULT":
            return {
                ...state,
                queryRun: true,
                error: false

            }
        case "UPLOAD_RESULT_ERROR":
            return {
                ...state,
                error: true,
                queryRun: true
            }
        case "CLEAR_QUERY_STATE":
            return {
                ...state,
                queryRun: false,
                error: false
            }
        case "LOGOUT":
            return {
                ...state,
                queryRun: false,
                error: false
            }

        default:
            return state;
    }
}
export default resultReducer;