const initialData = {
    queryRun: false,
    error: false
}
const voucherReducer = (state = initialData, action) => {
    switch (action.type) {
        case "UPLOAD_VOUCHER":
            return {
                ...state,
                queryRun: true,
                error: false

            }
        case "UPLOAD_VOUCHER_ERROR":
            return {
                ...state,
                error: true,
                queryRun: true

            }
        case "CLEAR_QUERY_STATE":
            return {
                ...state,
                queryRun: false,
                error: false
            }
        case "LOGOUT":
            return {
                ...state,
                queryRun: false,
                error: false
            }


        default:
            return state;
    }
}
export default voucherReducer;