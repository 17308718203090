import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react'
// import from store.js
import { store, persistor } from './store'
import { Provider } from "react-redux";
import Dashboard from "./Dashboard";
import Contactus from "./staticSite/components/Pages/Contactus";
import About from "./staticSite/components/Pages/About";
import Admissions from "./staticSite/components/Pages/Admissions";
import Signin from "./react-redux/components/Signin";
import AdminPage from "./react-redux/components/admin/AdminPage";
import TeacherLandingPage from "./react-redux/components/teacher/TeacherLandingPage";
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route path="/contact" element={<Contactus />} />
            <Route path="/about" element={<About />} />
            <Route path="/admissions" element={<Admissions />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/teacher" element={<TeacherLandingPage />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
