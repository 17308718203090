import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useState } from "react";
import {
    AppBar,
    Toolbar,
    CssBaseline,
    Typography,
    makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    bar: {
        backgroundColor: "#202256",
    },
}));

const Navbar = () => {
    const classes = useStyles();

    return (
        <div>
            <CssBaseline />
            <AppBar position="static" className={classes.bar}>
                <Toolbar>
                    <Typography variant="h6">
                        <div className="navbar-text">
                            <div className="navbar-heading">
                                The Arqam School { }
                            </div>
                            <div className="navbar-subheading ml-2 mt-1">
                                Chak Jhumra
                            </div>
                        </div>
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Navbar;
