import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/now-ui-kit.css";
import "../../assets/demo/demo.css";
function ProfilePageHeader() {
    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth > 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });
    return (
        <>
            <div
                className="page-header clear-filter page-header-small"
                filter-color="blue"
            >
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage:
                            "url(" + require("../../assets/imgs/p8.JPG") + ")",
                    }}
                    ref={pageHeader}
                ></div>
                <Container>

                </Container>
            </div>
        </>
    );
}

export default ProfilePageHeader;
