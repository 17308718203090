import axios from "axios";
import { address } from "./server";

export const uploadVoucher = (selectedFile, studentId, tutionFee, admissionFee, regFee, examinationFee, discount, arrears, payable) => {

    return async dispatch => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("studentId", studentId);
        formData.append("tutionFee", tutionFee);
        formData.append("admissionFee", admissionFee);
        formData.append("regFee", regFee);
        formData.append("examinationFee", examinationFee);
        formData.append("discount", discount);
        formData.append("arrears", arrears);
        formData.append("payable", payable);

        const response = await axios.post(`${address}/api/user/uploadVoucher`, formData)
            .then(res => {
                console.log(res.data)
                dispatch({
                    type: "UPLOAD_VOUCHER",

                    payload: res.data
                });
            }
            )
            .catch(err => {
                console.log(err)
                dispatch({
                    type: "UPLOAD_VOUCHER_ERROR",
                });
            }
            );
    }
}

export const clearQueryState = () => {
    return {
        type: "CLEAR_QUERY_STATE"
    }
}
