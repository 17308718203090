import React, { useState, useEffect } from 'react'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { addAnnouncement, clearQueryState } from '../../actions/annoucementAction';

import { useDispatch, useSelector } from 'react-redux';
const Announcement = () => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('None');
    const [isLoading, setIsLoading] = useState(false);

    let queryRun = useSelector(state => state.announcementReducer.queryRun);
    let class_Var = useSelector(state => state.loginReducer.user.grade);
    let section = useSelector(state => state.loginReducer.user.section);

    useEffect(() => {
        if (queryRun) {
            setIsLoading(false);
            alert('Announcement added successfully');
            dispatch(clearQueryState());
        }
    }
        , [queryRun])

    // if (isLoading == true) {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //         alert("Connection Error");
    //     }

    //         , 10000);
    // }
    const handleSubmit = () => {
        if (title === '' || description === '') {
            alert('Please fill all the fields');
        } else {
            setIsLoading(true);
            let cs = class_Var + "-" + section
            let data = {
                title: title,
                description: description,
                type: type,
                class: cs
            }
            dispatch(addAnnouncement(data));
            setTitle('');
            setDescription('');
            setType('None');
        }
    }

    if (isLoading) {
        return <div className='addstudent-loader'>
            < CircularProgress />
        </div >
    }

    return (
        <div>
            <div className="home-heading mt-3">
                Make Announcement
            </div>
            <Divider />
            <div className='content'>
                <Box component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        margin: '0 auto',
                        maxWidth: '500px',
                        padding: '0px',
                        width: '100%',
                        marginTop: '20px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        backgroundColor: '#f5f5f5',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',

                    }}
                    noValidate
                    autoComplete="off">
                    <TextField

                        id="outlined-required"
                        label="Title"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        fullWidth
                        value={description}
                        rows={10}
                        onChange={(e) => setDescription(e.target.value)}

                    />

                    <div className='select-container'>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={type}
                            label="Class"
                            onChange={(e) => setType(e.target.value)}
                            name="className"
                        >
                            <MenuItem value="None">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"class"}>Class Announcement</MenuItem>
                            <MenuItem value={"all"}>All</MenuItem>
                        </Select>
                    </div>
                    <div className='mb-2'>
                        <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                    </div>
                </Box>
            </div>
        </div>

    )
}

export default Announcement