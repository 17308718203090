const initialState = {
    user: {},
    queryRun: false,
    allowedToMarkAttendance: false,
    classStuds: {}

}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CLASS_STUDENTS":

            return {
                ...state,
                classStuds: action.payload,
                queryRun: false
            }
        case "GET_CLASS_STUDENTS_ATTENDANCE":
            return {
                ...state,
                user: action.payload,
                // queryRun: true
            }
        case "ATTENDANCE_MARKED":
            return {
                ...state,
                user: action.payload,
            }

        case "MARK_ATTENDANCE":
            return {
                ...state,
                queryRun: true
            }
        case "CLEAR_QUERY_STATE":
            return {
                ...state,
                queryRun: false
            }
        case "LOGOUT":
            return {
                ...state,
                user: {},
                queryRun: false,
                allowedToMarkAttendance: false,
                classStuds: {}
            }



        default:
            return state;

    }
}

export default userReducer;