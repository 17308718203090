import React from "react";
import ProfilePageHeader from "../Headers/ProfilePageHeader";
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useState, useEffect } from "react";
import DashboardNavbar from "../JJNAVBARS/DashboardNavbar";
import admission_form from "../../assets/admission_form.pdf"
import DarkFooter from "../Footers/DarkFooter";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/now-ui-kit.css";
import "../../assets/demo/demo.css";
const Admissions = () => {
  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DashboardNavbar />
      <div className="wrapper">
        <ProfilePageHeader />

        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Admissions</h2>
                <h5 className="description">Admission Fee</h5>
              </Col>
            </Row>
            {/* create a table */}
            <Row>
              <Col className="ml-auto mr-auto text-center " md="8">
                <table className="table">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>Description</th>
                      <th>Charges (PKR)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* <td>1</td> */}
                      <td>Admission Fee</td>
                      <td>5000/-</td>
                    </tr>
                    <tr>
                      {/* <td>2</td> */}
                      <td>Admission Fee if Siblings</td>
                      <td>2500/-</td>
                    </tr>
                  </tbody>

                </table>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center mt-5" md="8">
                <h5 className="description">Monthly Fee Packages</h5>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center " md="8">
                <table className="table">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>Description</th>
                      <th>Charges (PKR)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* <td>1</td> */}
                      <td>Registration Fee</td>
                      <td>1000/-</td>
                    </tr>
                    <tr>
                      {/* <td>2</td> */}
                      <td>Pre-Nursery to Eight</td>
                      <td>1900/-</td>
                    </tr>
                    <tr>
                      {/* <td>3</td> */}
                      <td>Ninth</td>
                      <td>2500/-</td>
                    </tr>
                    <tr>
                      {/* <td>4</td> */}
                      <td>Examination Fund (Per Term) Twice in a Year
                      </td>
                      <td>1000/-</td>
                    </tr>
                    <tr>
                      {/* <td>5</td> */}
                      <td>Admission Process Charges
                      </td>
                      <td>1000/-</td>
                    </tr>
                  </tbody>

                </table>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h5 style={{ fontWeight: 'bold' }} className="description">Documents Required</h5>
                <p className="description">Copy of Student's B-Form and Father's CNIC
                </p>
                <p className="description"> Leaving Certificate of the School last attended</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center mt-5" md="8">
                <h5 >The school reserves the right to change its policies and fee structure at any time.</h5>
              </Col>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <a href={admission_form} download>
                  <Button
                    className="btn-round"
                    color="info"
                    href={admission_form}
                    download
                    size="lg"
                  >
                    Download Admission Form
                  </Button>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DarkFooter />
    </>
  );
};

export default Admissions;
