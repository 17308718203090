import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import DashboardHeader from "../JJHEADERS/DashboardHeader";
import DashboardNavbar from "../JJNAVBARS/DashboardNavbar";
import DarkFooter from "../Footers/DarkFooter";
import { useState, useEffect } from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/now-ui-kit.css";
import "../../assets/demo/demo.css";
const Page = () => {

  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DashboardNavbar />
      <div className="wrapper">
        <DashboardHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Who we are?</h2>
                <h5 className="description">
                  The Arqam has particular importance and unique grace in the
                  history of Islam. It is the pioneer educational institution of
                  the Muslim society that came into existence under Rasool Ullah
                  (S.A.W.W), The Prophet of Islam.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/imgs/p7.JPG") + ")",
                    }}
                  >

                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/imgs/p9.JPG") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/imgs/p2.JPG") + ")",
                    }}
                  ></div>
                  <h3>
                    This was the basic school of Islamic thought, established at
                    the residence of Hazrat Zaid Bin Arqam (R.A.A), the
                    well-known companion of the Holy Prophet (S.A.W.W) with a
                    view to building the character of the people coming into the
                    circle of Islam.{" "}
                  </h3>
                  <p>
                    Keeping that first glorious and significant Islamic
                    educational academy in view, this institution has been named
                    THE ARQAM SCHOOL.{" "}
                  </p>
                  <p>
                    The primary aim and idea of THE ARQAM SCHOOL is to earn the
                    pleasure of Allah through the acts of education, training,
                    and human service in accordance with the doctrines of Allah
                    and Sayings of the Holy Prophet (S.A.W.W){" "}
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section section-team text-center">
          <Container>
            <h2 className="title">Here is our team</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("../../assets/imgs/avatar.png")}
                    ></img>
                    <h4 className="title">Shazia Ahsan</h4>
                    <p className="category text-info">Principal</p>
                    <p className="description">
                      She is an experienced, loyal and determined motivator, excellent listener, and visionary who leads the team. She believes in teamwork and wants to leave a spark wherever she goes. She makes plans and supervises her team to succeed by entirely using her skills.
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("../../assets/imgs/avatar.png")}
                    ></img>
                    <h4 className="title">Amna Tabassum
                    </h4>
                    <p className="category text-info">Principal (KRC)</p>
                    <p className="description">
                      She has been dedicated to the field of education for more than 12 years. She has worked with students ranging in grade kindergarten to 10th in various roles. Now, she's here to bring her skills as an educational leader to The Arqam Schools (KRC Jhumra).

                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("../../assets/imgs/avatar.png")}
                    ></img>
                    <h4 className="title">Ammara Shaheen
                    </h4>
                    <p className="category text-info">Admin</p>
                    <p className="description">

                      She has been working with The Arqam School Jhumra since 2013. She was recruited as a teacher but was promoted to coordinator due to her neverending commitment and excellent results. Recently, she has started working as a coordinator.

                    </p>

                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("../../assets/imgs/avatar.png")}
                    ></img>
                    <h4 className="title">Aneeqa Aslam
                    </h4>
                    <p className="category text-info">Coordinator </p>
                    <p className="description">

                      Aneeqa also joined The Arqam School as a teacher. But now she is a Coordinator of the high section. She is enthusiastic and passionate. And she has excellent command over teaching. She also trains co-workers to polish their teaching skills. She strives for the betterment of the institution.
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("../../assets/imgs/avatar.png")}
                    ></img>
                    <h4 className="title">Nadia Noreen
                    </h4>
                    <p className="category text-info">Coordinator</p>
                    <p className="description">

                      Nadia is an honest and dedicated member of The Arqam School. She joined as a teacher in 2011 and is now working as a coordinator. She enjoys and believes in activity-based learning for students. Her qualities that have helped the institute to flourish are honesty and dedication. Nadia wants

                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("../../assets/imgs/avatar.png")}
                    ></img>
                    <h4 className="title">Rida Ahmed
                    </h4>
                    <p className="category text-info">Coordinator</p>
                    <p className="description">
                      Rida has been connected with the noble profession of teaching for five years. As a teacher, she worked with dedication and passion in this institution. She believes in teamwork and Cooperation, remains positive every time, and thinks this is the novelty of the teacher.


                    </p>

                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section section-contact-us text-center"></div>
      </div>
      <DarkFooter />
    </>
  );
};

export default Page;
