import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GradingIcon from '@mui/icons-material/Grading';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CampaignIcon from '@mui/icons-material/Campaign';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Navbar from '../Navbar';
import Home from './Home';
import Attendance from './Attendance';
import Result from './Result'
import Vouchers from './Vouchers';
import Announcement from './Announcement'
import Addstudent from './Addstudent';
import Loading from '../Loading';
import { logout } from '../../actions/loginAction'


const drawerWidth = 240;


const TeacherLandingPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selected, setSelected] = useState("Home");
    const [isLoading, setIsLoading] = useState(true);
    let allowed = useSelector(state => state.loginReducer.allowed);

    useEffect(() => {
        setIsLoading(false);

        if (!allowed) {
            navigate("/");
        }
    }, [allowed])

    if (isLoading) {
        return <Loading />
    }


    return (
        <div className='app'>
            <Navbar />
            <div className='main-container'>
                <div className='main-content'>
                    {selected === "Home" ? <Home /> :
                        selected === "Add-Student" ? <Addstudent /> :
                            selected === "Announcements" ? <Announcement /> :
                                selected === "Attendance" ? <Attendance /> :
                                    selected === "Vouchers" ? <Vouchers /> :
                                        selected === "Results" ? <Result /> :
                                            null
                    }
                </div>
            </div>
            <div className='sidebar-container'>
                <Box sx={{ display: 'flex' }}>
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                                marginTop: '64px',
                            },
                        }}
                        variant="permanent"
                        anchor="left"
                    >
                        <List>
                            <ListItem>
                                <ListItemButton onClick={() => setSelected("Home")}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Home" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => setSelected("Attendance")}>
                                    <ListItemIcon>
                                        <CalendarMonthIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Attendance" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => setSelected("Results")}>
                                    <ListItemIcon>
                                        <GradingIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Results" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => setSelected("Vouchers")}>
                                    <ListItemIcon>
                                        <AccountBalanceIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Vouchers" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => setSelected("Announcements")}>
                                    <ListItemIcon>
                                        <CampaignIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Announcements" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => setSelected("Add-Student")}>
                                    <ListItemIcon>
                                        <PersonAddIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Add Student" />
                                </ListItemButton>
                            </ListItem>

                        </List>
                        <Divider />
                        <div className="sidebar-logout-button-container">
                            <List>
                                <ListItem >
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <Button variant="contained" color="error" onClick={() => {
                                        dispatch(logout());
                                        navigate('/signin');
                                    }}>
                                        Logout
                                    </Button>
                                </ListItem>
                            </List>
                        </div>


                    </Drawer>

                </Box>

            </div>

        </div>
    );
}
export default TeacherLandingPage
