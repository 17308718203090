import React, { useState, useEffect } from 'react'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux'
import { getClassStudentsAttendance, attendanceMarked } from '../../actions/userActions';
import { markAttendance, clearQueryState } from '../../actions/attendanceActions';
const Attendance = () => {
    const dispatch = useDispatch();
    const [get, setGet] = useState(true);
    const [classStudentsIds, setClassStudentsIds] = useState([]);
    const className = useSelector(state => state.loginReducer.user.grade);
    const classSection = useSelector(state => state.loginReducer.user.section);
    const [attendance, setAttendance] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    let classStudentsData = useSelector(state => state.userReducer.user);
    let queryRun = useSelector(state => state.userReducer.queryRun);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }

            , 1000);
    }
        , []);


    useEffect(() => {
        if (queryRun) {
            setIsLoading(false);
            alert('Attendance Marked Successfully');
            dispatch(clearQueryState());
            dispatch(attendanceMarked());
        }
    }
        , [queryRun])
    useEffect(() => {
        if (get) {
            let cls = className + "-" + classSection;
            dispatch(getClassStudentsAttendance(cls));
            setGet(false);
        }
    }, [get])



    if (classStudentsData != "Already updated" && classStudentsData.length > 0 && attendance.length === 0) {
        let classStudentsIds = classStudentsData.map(student => student.id);
        setClassStudentsIds(classStudentsIds);
        // make array of this size
        let arr = new Array(classStudentsData.length);
        // fill the array with ""
        arr.fill("");
        setAttendance(arr);
    }

    const handleChange = (event, index) => {
        const newAttendance = [...attendance];
        newAttendance[index] = event.target.value;
        setAttendance(newAttendance);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let attendanceData = {
            attendance: attendance,
            className: className,
            classSection: classSection,
            classStudentsIds: classStudentsIds
        }
        setIsLoading(true);
        dispatch(markAttendance(attendanceData));
    }
    if (isLoading) {
        return <div className='addstudent-loader'>
            < CircularProgress />
        </div >
    }
    return (
        <div>
            <div className="home-heading mt-3">
                Mark Attendance
            </div>
            <Divider />

            {classStudentsData == "Already updated" ?
                <div className='attendance-container'>
                    <div className='attendance-heading'>
                        Attendance Already Marked!
                    </div>
                </div>
                :
                <Box
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        margin: '0 auto',
                        maxWidth: '500px',
                        padding: '0px',
                        width: '100%',
                        marginTop: '20px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        backgroundColor: '#f5f5f5',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',

                    }}
                    noValidate
                    autoComplete="off">

                    <div className='attendance-container'>
                        <TableContainer component={Paper} sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },

                            backgroundColor: '#f5f5f5',

                        }} >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><h3>Name</h3></TableCell>
                                        <TableCell align="right"><h3>Present</h3></TableCell>
                                        <TableCell align="right"><h3>Absent</h3></TableCell>
                                        <TableCell align="right"><h3>Leave</h3></TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {classStudentsData.map((name, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                <h5>{name.name}</h5>
                                            </TableCell>
                                            <TableCell align="right">
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="status" name="status1" value={attendance[index]} onChange={(e) => handleChange(e, index)}>
                                                        <FormControlLabel value="Present" control={<Radio />} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="right">
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="status" name="status1" value={attendance[index]} onChange={(e) => handleChange(e, index)}>
                                                        <FormControlLabel value="Absent" control={<Radio />} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="right">
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="status" name="status1" value={attendance[index]} onChange={(e) => handleChange(e, index)}>
                                                        <FormControlLabel value="Leave" control={<Radio />} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className='mt-2 mb-2'>
                            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                        </div>
                    </div>




                </Box>
            }
        </div>
        // </div >
    )
}

export default Attendance