import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import announcementReducer from "./announcementReducer";
import userReducer from "./userReducer";
import voucherReducer from "./voucherReducer";
import resultReducer from "./resultReducer"
export default combineReducers({
    loginReducer,
    announcementReducer,
    userReducer,
    voucherReducer,
    resultReducer
});