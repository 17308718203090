import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from '@material-ui/core';


const Home = () => {

    const user = useSelector(state => state.loginReducer.user)

    return (
        <div>
            <div className="home-heading mt-3">
                Welcome {user.name}
            </div>
            <Divider />
            <div className='home-subheading mt-2'>
                Class Assigned: {user.grade}-{user.section}
            </div>
        </div>
    )
}

export default Home