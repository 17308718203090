import React, { useState, useEffect } from 'react'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { getClassStudents } from '../../actions/userActions';
import { uploadVoucher, clearQueryState } from '../../actions/voucherActions';
const Vouchers = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [tutionFee, setTutionFee] = useState(0);
    const [admissionFee, setAdmissionFee] = useState(0);
    const [regFee, setRegFee] = useState(0);
    const [examinationFee, setExaminationFee] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [arrears, setArrears] = useState(0);
    const [payable, setPayable] = useState(0);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [get, setGet] = useState(true);
    const [studentId, setStudentId] = useState("None");
    const className = useSelector(state => state.loginReducer.user.grade);
    const classSection = useSelector(state => state.loginReducer.user.section);
    let queryRun = useSelector(state => state.voucherReducer.queryRun);
    let error = useSelector(state => state.voucherReducer.error);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }
            , 1000);
    }
        , []);
    useEffect(() => {
        if (queryRun && !error) {
            setIsLoading(false);
            alert('Voucher added successfully');
            dispatch(clearQueryState());
        }
        else if (queryRun && error) {
            setIsLoading(false);
            alert('Failed!');
            dispatch(clearQueryState());
        }
    }
        , [queryRun])
    useEffect(() => {
        setPayable(tutionFee + admissionFee + regFee + examinationFee - discount - arrears);
    }
        , [tutionFee, admissionFee, regFee, examinationFee, discount, arrears])
    useEffect(() => {
        if (get) {
            let cs = className + "-" + classSection;
            dispatch(getClassStudents(cs));
            setGet(false);
        }
    }, [get])
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const handleSubmission = () => {

        if (tutionFee === 0) {
            alert('Please fill the required field *');
        }
        else if (isFilePicked == false) {
            alert('Please select a file');
        }
        else {
            setIsLoading(true);
            dispatch(uploadVoucher(selectedFile, studentId, tutionFee, admissionFee, regFee, examinationFee, discount, arrears, payable));
            setSelectedFile(null);
            setIsFilePicked(false);
            setStudentId("None");
            setTutionFee(0);
            setAdmissionFee(0);
            setRegFee(0);
            setExaminationFee(0);
            setDiscount(0);
            setArrears(0);
            setPayable(0);
        }
    };
    const handleChangeId = (event) => {
        setStudentId(event.target.value);
    }
    let classStudents = useSelector(state => state.userReducer.classStuds);
    if (isLoading) {
        return <div className='addstudent-loader'>
            < CircularProgress />
        </div >
    }
    return (
        <div>
            <div className="home-heading mt-3">
                Add Fee Voucher
            </div>
            <Divider />
            <div className='content'>
                <Box component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        // margin: '0 auto',
                        // maxWidth: '500px',
                        padding: '0px',
                        width: '100%',
                        marginTop: '20px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        backgroundColor: '#f5f5f5',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',

                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div className="student-name-select-container ml-2 mt-2">
                        <InputLabel id="demo-simple-select-helper-label" className=''>Student</InputLabel>
                        <Select
                            className='ml-2'
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={studentId}
                            label="Class"
                            onChange={handleChangeId}
                            name="className"
                        >
                            <MenuItem value="None">
                                <em>None</em>
                            </MenuItem>
                            {classStudents != null && classStudents.map((student, index) => {
                                return (
                                    <MenuItem value={student.id} key={index}>{student.name}</MenuItem>
                                )
                            }
                            )}


                        </Select>
                    </div>
                    <TextField
                        required
                        id="outlined-required"
                        label="Tution Fee"
                        value={tutionFee}
                        onChange={(e) => setTutionFee(parseInt(e.target.value) || 0)}
                    />
                    <TextField
                        id="outlined"
                        label="Admission Fee"
                        value={admissionFee}
                        onChange={(e) => setAdmissionFee(parseInt(e.target.value) || 0)}
                    />

                    <TextField
                        required
                        id="outlined"
                        label="Reg. Fee"
                        value={regFee}
                        onChange={(e) => setRegFee(parseInt(e.target.value) || 0)}
                    />
                    <TextField
                        id="outlined"
                        label="Examination Fee"
                        value={examinationFee}
                        onChange={(e) => setExaminationFee(parseInt(e.target.value) || 0)}

                    />
                    <TextField
                        id="outlined"
                        label="Discount"
                        value={discount}
                        onChange={(e) => setDiscount(parseInt(e.target.value) || 0)}
                    />
                    <TextField
                        required
                        id="outlined"
                        label="Arrears"
                        value={arrears}
                        onChange={(e) => setArrears(parseInt(e.target.value) || 0)}
                    />
                    <TextField
                        disabled
                        id="outlined-disabled"
                        label="Payable"
                        value={payable}
                    />
                </Box>

                <Box component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        // flexWrap: 'wrap',
                        // margin: '0 auto',
                        // maxWidth: '500px',
                        padding: '0px',
                        width: '100%',
                        height: '100%',
                        marginTop: '20px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        backgroundColor: '#f5f5f5',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
                        marginLeft: '20px',

                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div className='file-info'>
                        {isFilePicked ? (
                            <div>
                                <p>Filename: {selectedFile.name}</p>
                                <p>Size in bytes: {selectedFile.size}</p>

                            </div>
                        ) : (
                            <p>Select a file to show details</p>
                        )}
                    </div>
                    <div className='select-button'>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            <input
                                onChange={changeHandler}
                                type="file"
                                hidden
                            />
                            Select File
                        </Button>
                    </div>
                    <div className='upload-button'>
                        <Button
                            variant="contained"
                            component="label"
                            onClick={handleSubmission}
                        >
                            Upload
                        </Button>
                    </div>

                </Box>
            </div>
        </div >

    )
}

export default Vouchers