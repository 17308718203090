import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import DashboardNavbar from "../JJNAVBARS/DashboardNavbar";
import DarkFooter from "../Footers/DarkFooter";
import { useState, useEffect } from "react";
import ProfilePageHeader from "../Headers/ProfilePageHeader";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/now-ui-kit.css";
import "../../assets/demo/demo.css";
const Contactus = () => {

  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DashboardNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Contact us</h2>
                <h5 className="description">We are here to help you.</h5>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Find us at the office</h4>
                    <p>
                      Khurrianwala Rd, <br />
                      Chak Jhumra, <br />
                      Faisalabad, Punjab
                    </p>
                    <p>
                      Chamra Mandi, <br />
                      Chak Jhumra,
                      <br />
                      Faisalabad, Punjab
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-mobile" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Give us a ring</h4>
                    <p>
                      Khurrianwala Road Campus <br />
                      0325 831 7777 <br />
                    </p>
                    <p>
                      Chamra Mandi Campus <br />
                      0325 831 0007 <br />
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-email-85" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Email us</h4>
                    <p>tasjhumra@gmail.com</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DarkFooter />
    </>
  );
};

export default Contactus;
