import axios from "axios";
import { address } from "./server";

export const login = (loginId, loginPassword) => {
    const body = {
        password: loginPassword,
        id: loginId,

    };
    return (dispatch) => {
        console.log(address + "/api/user/login");
        axios.post(address + "/api/user/login", body)
            .then((response) => {
                if (response.data === "User not found") {
                    dispatch({
                        type: "LOGIN_FAILED",
                        payload: response.data
                    });
                }
                else {
                    dispatch({
                        type: "LOGIN",
                        payload: response.data,
                    });
                }
            }
            )
            .catch((error) => {
                console.log(error, "ERROR IS HERE");
            }
            );

    }
}                         //    //        //        //  //    //         //                                                   // 
export const addUser = (name, password, className, id, dob, contact, fatherName, pDates, aDates, lDates, type, vouchers, section, teacher) => {
    const body = {
        name: name,
        password: password,
        grade: className,
        section: section,
        id: id,
        dob: dob,
        emergencyContact: contact,
        fatherName: fatherName,
        presentDates: pDates,
        absentDates: aDates,
        leaveDates: lDates,
        type: type,
        vouchers: vouchers,
        teacher: teacher,
        token: "",
        results: []
    }
    return (dispatch) => {
        axios.post(address + "/api/user", body)
            .then((response) => {
                dispatch({
                    type: "ADD_USER",
                    payload: response.data,
                });
            }
            )
            .catch((error) => {
                console.log(error, "ERROR IS HERE");
            }
            );
    }
}
export const logout = () => {
    return {
        type: "LOGOUT",
    }
}

export const clearQueryState = () => {
    return {
        type: "CLEAR_QUERY_STATE"
    }
}
