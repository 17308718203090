/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/now-ui-kit.css";
import "../../assets/demo/demo.css";
function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a>The Arqam Schools</a>
            </li>
            <li>
              <a>Chak Jhumra</a>
            </li>
          </ul>
          
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}

          <a href="/signin"> Sign in</a>
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
