import React from "react";
import Page from "./staticSite/components/JJLANDINGPAGE/Page.js";
import "./staticSite/assets/css/bootstrap.min.css";
import "./staticSite/assets/css/now-ui-kit.css";
import "./staticSite/assets/demo/demo.css";
const Dashboard = () => {
  return (
    <div>
      <Page />
    </div>
  );
};

export default Dashboard;
