import React from "react";
import ProfilePageHeader from "../Headers/ProfilePageHeader";
import DashboardNavbar from "../JJNAVBARS/DashboardNavbar";
import DarkFooter from "../Footers/DarkFooter";
import {

  Container,
  Row,
  Col,
} from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/now-ui-kit.css";
import "../../assets/demo/demo.css";
const About = () => {
  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DashboardNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Director Message</h2>
                <h5 className="description">
                  We appreciate your interest in The Arqam Schools! The Arqam
                  Schools Chak Jhumra nurtures the students' ability to take on challenging
                  problems and do meaningful work, and help them become
                  compassionate, intelligent learners. We are committed to
                  nurturing our students' social-emotional & spiritual
                  development, helping them build the kinds of skills that lead
                  to success both today and in their futures. We are committed
                  to building a solid community of families — with a shared
                  commitment to the success of all our children and regular
                  opportunities to contribute skills, talents, and expertise. We
                  aim to show what's possible when engaging students in
                  meaningful work grounded in their own experience, interests,
                  and questions. We're glad you're interested in applying to The
                  Arqam Schools Chak Jhumra. We think you've made a great choice! <br />
                  Sincerely
                </h5>
                <Row>
                  <Col md="6">
                    <h4 className="title">Azhar Javed</h4>
                    <h4 className="description">Director</h4>
                  </Col>
                  <Col md="6">
                    <h4 className="title">Mazhar Javed</h4>
                    <h4 className="description">Director</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <h2 className="title"> Our Mission </h2>
                  <h5 className="description">
                    The Arqam School's mission is to earn the pleasure of Allah
                    through the acts of education, training and human service in
                    accordance with the doctrines of Allah and Sayings of the
                    Holy Prophet (S.A.W.W)
                  </h5>

                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/imgs/p6.JPG") + ")",
                    }}
                  ></div>
                </Col>

                <Col md="5">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/imgs/p5.JPG") + ")",
                    }}
                  ></div>
                  <h2 className="title">Our Vision</h2>
                  <h5 className="description">
                    Our ultimate target is the achievement of{" "}
                    <strong className="title">
                      {" "}
                      “Excellence during the life in this world as well as the
                      life in the Hereafter.”
                    </strong>{" "}
                    which has been very vividly spelled out in our insignia.{" "}
                  </h5>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <DarkFooter />
    </>
  );
};

export default About;
