import axios from "axios";
import { address } from "./server";

export const uploadResult = (selectedFile, studentId, studentClass, term) => {
    return async dispatch => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("studentId", studentId);
        formData.append("studentClass", studentClass)
        formData.append("term", term)
        const response = await axios.post(`${address}/api/user/uploadResult`, formData)
            .then(res => {
                dispatch({
                    type: "UPLOAD_RESULT",

                    payload: res.data
                });
            }
            )
            .catch(err => {
                console.log(err)
                dispatch({
                    type: "UPLOAD_RESULT_ERROR",
                });
            }
            );
    }
}

export const clearQueryState = () => {
    return {
        type: "CLEAR_QUERY_STATE"
    }
}
