const initialData = {
    allowed: false,
    message: "",
    queryRun: false,
};


const announcementReducer = (state = initialData, action) => {
    const data = action.payload;
    switch (action.type) {
        case "ADD_ANNOUNCEMENT":
            return {
                ...state,
                queryRun: true,
            };
        case "ADD_ANNOUNCEMENT_FAILED":
            return {
                ...state,
                queryRun: true,
            };
        case "CLEAR_QUERY_STATE":
            return {
                ...state,
                queryRun: false,
            }
        case "LOGOUT":
            return {
                ...state,
                allowed: false,
                message: "",
                queryRun: false,
            }

        default:
            return state;
    }
}
export default announcementReducer;