import React, { useEffect } from 'react'
import PersonIcon from "@mui/icons-material/Person";
import PasswordIcon from "@mui/icons-material/Password";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import sha256 from 'js-sha256';
import Loading from '../components/Loading';
import { login, clearQueryState, logout } from "../actions/loginAction";
import "../assets/css/main.css";
const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hashedPassword, setHashedPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(state => state.loginReducer.user);
  const allowed = useSelector(state => state.loginReducer.allowed);
  let queryRun = useSelector(state => state.loginReducer.queryRun);
  console.log(queryRun)

  useEffect(() => {

    if (queryRun) {
      if (allowed) {
        if (user.type == "admin") {
          setIsLoading(false);
          navigate("/admin");
          dispatch(clearQueryState());
        }
        else if (user.type == "teacher") {
          setIsLoading(false);
          navigate("/teacher");
          dispatch(clearQueryState());
        }
        else if (user.type == "student") {
          setIsLoading(false);
          alert("Please enter valid credentials");
          dispatch(clearQueryState());
          dispatch(logout());
        }
      }
      else {
        alert("Please enter valid credentials");
        setIsLoading(false);
        dispatch(clearQueryState());
      }
    }
  }, [queryRun])
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username != "" && password != "") {
      dispatch(login(username, hashedPassword));
      setUsername("");
      setPassword("");
      setHashedPassword("");
      setIsLoading(true);
    }
    else {
      alert("Please enter all the fields");

    }
  }
  if (isLoading) {
    return <Loading />
  }


  return (

    <div className='app login-background'>

      <div className='app-header-text mt-5'>
        <h2>Welcome to Teachers Portal</h2>
      </div>
      <div className='app-body'>
        <div className='login-container'>
          <div className='login-form'>
            <div className="login-form-header">
              <h3>Login</h3>
            </div>
            <div className="login-form-body">
              <form>
                <span className="login-form-input-icon">
                  <PersonIcon />
                </span>

                <TextField
                  className="login-form-input"
                  id="standard-basic"
                  label="username"
                  variant="standard"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <div className="mt-1">
                  <span className="login-form-input-icon">
                    <PasswordIcon />
                  </span>
                  <TextField
                    className="login-form-input"
                    id="standard-basic"
                    label="password"
                    variant="standard"
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                      setHashedPassword(sha256(e.target.value))
                    }}
                  />
                </div>
              </form>
              <div className="mt-3">
                <Button color="primary" variant="contained" style={{ marginBottom: "10px" }} onClick={handleSubmit}>
                  Login
                </Button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Signin